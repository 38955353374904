const useIsL2 = () => {
	// const { activeChain } = Connector.useContainer()
	// const isL2 = useMemo(
	// 	() =>
	// 		notNill(activeChain)
	// 			? [chain.tenderly.id, chain.tenderly.id].includes(activeChain.id as 10 | 420)
	// 			: false,
	// 	[activeChain]
	// )
	return false
}

export default useIsL2
